<template>
  <component
    :is="cards[item.type]"
    :item="item"
    @click.native="$emit('select', item)"
    :selected="selected"
    :compact="compact"
  />
</template>

<script>
const cardEmail = () => import("./cards/card-email.vue");
const cardCall = () => import("./cards/card-call.vue");
const cardStatusChange = () => import("./cards/card-status-change.vue");
const cardNote = () => import("./cards/card-note.vue");
const cardTask = () => import("./cards/card-task.vue");

export default {
  name: "checklist-item",
  props: {
    item: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
    },
    compact: {
      type: Boolean,
      required: false,
    },
  },

  computed: {
    cards() {
      return {
        email: cardEmail,
        call: cardCall,
        status_change: cardStatusChange,
        note: cardNote,
        tasks: cardTask,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
